<script lang="ts">
import {Vue, Component} from 'vue-property-decorator';
import moment from "moment/moment";
import AbAxiosLeasingReporting from "../../views/banklogin/BankloginAxiosLeasingReporting";
import DatePicker from 'vue2-datepicker';
import './datepicker.scss';
import 'vue2-datepicker/locale/da';

@Component({
  name: 'sso-form-component',
  props: ['requestId', 'title'],
  components: { DatePicker }
})

export default class SsoFormComponent extends Vue {
  datePlaceholder: string = 'Vælg dato';
  dateFormat = 'DD-MM-YYYY';

  abAxios = new AbAxiosLeasingReporting();

  token: string = null;
  from: string = null;

  requested: boolean = false;
  emailSent: boolean = false;
  responseRecieved: boolean = false;
  spinnerShown: boolean = false;

  requestIds = [
    "Bestillingsark-Debitorskifte",
    "Bestillingsark-indfrielser",
    "Bestillingsark-forlaengelser"
  ]

  BestillingsarkDebitorskifte: any = {
    LeasingNumber: '',
    Date: '',
    ExitingDebitor: '',
    DebitorName: '',
    DebitorCVR: '',
    DebitorMail: '',
    DebitorAccount: '',
    DebitorPhone: '',
    DebitorSigner: '',
    DebitorMails: '',
    DebitorInsurance: '',
    DebitorSurety: '',
    DebitorSuretyName: '',
    DebitorSuretyCvr: '',
    DebitorSuretyEmail: '',
    DebitorSuretyPhone: '',
    DebitorSuretyAddress: '',
    DebitorSuretyZip: '',
    DebitorFee: '2500',
    ReRegistrationFee: '380',
    Allonge: 'Kunde',
    Remarks: ''
  };

  BestillingsarkIndfrielser: any = {
    LeasingNumber: '',
    RedemptionDate: '',
    BackDiscounting: 'Ja',
    RedemptionFee: '',
    PaymentAdviceCVR: '',
    PaymentAdviceCompanyName: '',
    PaymentAdviceMail: '',
    Remarks: ''
  };

  BestillingsarkForlaengelser: any = {
    LeasingNumber: '',
    DebitorCVR: '',
    ProlongMonths: '',
    ResidualType: '',
    ResidualValue: '',
    HandlingFee: '2995',
    CostsCofinanced: 'Ja',
    DebitorSigner: '',
    DebitorMails: '',
    Allonge: 'Kunde',
    Remarks: ''
  };

  mounted() {
    setTimeout(() => {
      if (this.$props.requestId == this.requestIds[0]) {
        this.focusAndSetGetParams("BestillingsarkDebitorskifteLeasingNumber", "BestillingsarkDebitorskifteExitingDebitor");
      } else if (this.$props.requestId == this.requestIds[1]) {
        this.focusAndSetGetParams("BestillingsarkIndfrielserLeasingNumber", "BestillingsarkIndfrielserPaymentAdviceCVR");
      } else if (this.$props.requestId == this.requestIds[2]) {
        this.focusAndSetGetParams("BestillingsarkForlaengelserLeasingNumber", "BestillingsarkForlaengelserDebitorCVR");
      }
    }, 100);
  }

  public focusAndSetGetParams(leasingNoElementId: string, cvrNoElementId?: string) {
    const searchParams = new URL(location.href).searchParams;

    const myToken = searchParams.get('token');
    if (myToken) {
      this.token = myToken;
    }
    const myFrom = searchParams.get('from');
    if (myFrom) {
      this.from = myFrom;
    }

    if (leasingNoElementId.length) {
      const elementLeasingNo = document.getElementById(leasingNoElementId) as HTMLInputElement;
      elementLeasingNo.focus();

      const leasingnumber = searchParams.get('leasingnumber');
      if (leasingnumber) {
        elementLeasingNo.value = leasingnumber;
        if (this.$props.requestId == 'Bestillingsark-Debitorskifte') {
          this.BestillingsarkDebitorskifte.LeasingNumber = leasingnumber;
        } else if (this.$props.requestId == 'Bestillingsark-indfrielser') {
          this.BestillingsarkIndfrielser.LeasingNumber = leasingnumber;
        } else if (this.$props.requestId == 'Bestillingsark-forlaengelser') {
          this.BestillingsarkForlaengelser.LeasingNumber = leasingnumber;
        }
      }
    }

    if (cvrNoElementId.length) {
      const elementCvrNo = document.getElementById(cvrNoElementId) as HTMLInputElement;
      const cvrNo = searchParams.get('cvrno');
      if (cvrNo) {
        elementCvrNo.value = cvrNo;
        if (this.$props.requestId == 'Bestillingsark-Debitorskifte') {
          this.BestillingsarkDebitorskifte.DebitorCVR = cvrNo;
        } else if (this.$props.requestId == 'Bestillingsark-indfrielser') {
          this.BestillingsarkIndfrielser.PaymentAdviceCVR = cvrNo;
        } else if (this.$props.requestId == 'Bestillingsark-forlaengelser') {
          this.BestillingsarkForlaengelser.DebitorCVR = cvrNo;
        }
      }
    }
  }

  public disableDateNotFirst(date: Date) {
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    return diff > 0 || date.getDate() != 1;
  }

  public disableDateNotLast(date: Date) {
    const numberOfDaysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    return numberOfDaysInMonth != date.getDate();
  }

  private async submit() {
    if (!this.token || !this.from) {
      console.log('Missing vars')
      return;
    }

    this.responseRecieved = false;

    let bodyFormData = {
      'token': this.token,
      'subject': this.$props.title,
      'body': 'Formularen "' + this.$props.title + '" blev udfyldt med følgende informationer\n\n'
    };

    bodyFormData.body += 'Afsender: ' + this.from + '\n\n';

    if (this.$props.requestId == this.requestIds[0]) {
      bodyFormData.body +=
        'Leasingaftale nummer: ' + this.BestillingsarkDebitorskifte.LeasingNumber + '\n' +
        'Dato for debitorskifte: ' + this.BestillingsarkDebitorskifte.Date + '\n' +
        'CVR.nr. på udtrædende debitor: ' + this.BestillingsarkDebitorskifte.ExitingDebitor + '\n\n' +
        'Indtrædende debitor\n\n' +
        'Navn: ' + this.BestillingsarkDebitorskifte.DebitorName + '\n' +
        'CVR: ' + this.BestillingsarkDebitorskifte.DebitorCVR + '\n' +
        'Mail: ' + this.BestillingsarkDebitorskifte.DebitorMail + '\n' +
        'Reg. nr. og konto nr. til BS: ' + this.BestillingsarkDebitorskifte.DebitorAccount + '\n' +
        'Tlf. nr.: ' + this.BestillingsarkDebitorskifte.DebitorPhone + '\n' +
        'Hvem er underskriver (jf. tegningsregler): ' + this.BestillingsarkDebitorskifte.DebitorSigner + '\n' +
        'Mail på underskriver(e): ' + this.BestillingsarkDebitorskifte.DebitorMails + '\n' +
        'Forsikringsselskab: ' + this.BestillingsarkDebitorskifte.DebitorInsurance + '\n\n' +
        'Kautionist: ' + this.BestillingsarkDebitorskifte.DebitorSurety + '\n' +
        'Navn: ' + this.BestillingsarkDebitorskifte.DebitorSuretyName + '\n' +
        'CVR: ' + this.BestillingsarkDebitorskifte.DebitorSuretyCvr + '\n' +
        'E-mail: ' + this.BestillingsarkDebitorskifte.DebitorSuretyEmail + '\n' +
        'Tlf. nr.: ' + this.BestillingsarkDebitorskifte.DebitorSuretyPhone + '\n' +
        'Adresse: ' + this.BestillingsarkDebitorskifte.DebitorSuretyAddress + '\n' +
        'Postnummer: ' + this.BestillingsarkDebitorskifte.DebitorSuretyZip + '\n\n' +
        'Gebyr: ' + this.BestillingsarkDebitorskifte.DebitorFee + '\n' +
        'Gebyr for omregistrering i DMR: ' + this.BestillingsarkDebitorskifte.ReRegistrationFee + '\n' +
        'Allonge sendes til: ' + this.BestillingsarkDebitorskifte.Allonge + '\n' +
        'Særlige bemærkninger:\n' + this.BestillingsarkDebitorskifte.Remarks;
    } else if (this.$props.requestId == this.requestIds[1]) {
      bodyFormData.body +=
        'Leasingaftale nummer: ' + this.BestillingsarkIndfrielser.LeasingNumber + '\n' +
        'Indfrielsesdato: ' + this.BestillingsarkIndfrielser.RedemptionDate + '\n' +
        'Indfri med alle fremtidige ydelser (indfri med al fremtidig renteindtægt) eller indfri til nedskrevet balance (indfri uden fremtidig renteindtægt): ' + this.BestillingsarkIndfrielser.BackDiscounting + '\n' +
        'Indfrielsesgebyr (kan kun opkræves, hvis der vælges indfri til nedskrevet balance): ' + this.BestillingsarkIndfrielser.RedemptionFee + '\n\n' +
        'Betalingsadvis udstedes til\n\n' +
        'CVR: ' + this.BestillingsarkIndfrielser.PaymentAdviceCVR + '\n' +
        'Firmanavn: ' + this.BestillingsarkIndfrielser.PaymentAdviceCompanyName + '\n' +
        'Mail: ' + this.BestillingsarkIndfrielser.PaymentAdviceMail + '\n' +
        'Særlige bemærkninger:\n' + this.BestillingsarkIndfrielser.Remarks;
    } else if (this.$props.requestId == this.requestIds[2]) {
      bodyFormData.body +=
        'Leasingaftale nummer: ' + this.BestillingsarkForlaengelser.LeasingNumber + '\n' +
        'CVR.nr. på debitor: ' + this.BestillingsarkForlaengelser.DebitorCVR + '\n' +
        'Marginalen fra tidligere aftale bibeholdes.\n\n' +
        'Aftalen forlænges med x antal måneder: ' + this.BestillingsarkForlaengelser.ProlongMonths + '\n' +
        'Indtast enten ny ydelse eller ny restværdi: ' + this.BestillingsarkForlaengelser.ResidualType + '\n' +
        'Kr: ' + this.BestillingsarkForlaengelser.ResidualValue + '\n\n' +
        'Ekspeditionsgebyr: ' + this.BestillingsarkForlaengelser.HandlingFee + '\n' +
        'Skal omkostninger medfinansieres: ' + this.BestillingsarkForlaengelser.CostsCofinanced + '\n\n' +
        'Hvem er underskriver (jf. tegningsregler): ' + this.BestillingsarkForlaengelser.DebitorSigner + '\n' +
        'Mail på underskriver(e): ' + this.BestillingsarkForlaengelser.DebitorMails + '\n' +
        'Allonge sendes til: ' + this.BestillingsarkForlaengelser.Allonge + '\n\n' +
        'Særlige bemærkninger:\n' + this.BestillingsarkForlaengelser.Remarks;
    }

    this.requestMail(bodyFormData);
  }

  requestMail(bodyFormData: any) {
    const _self = this;

    _self.spinnerShown = true;
    _self.requested = true;

    this.abAxios.sendFormMail(bodyFormData, function succesCallback () {
      _self.spinnerShown = false;
      _self.responseRecieved = true;
      _self.emailSent = true;
    }, function errorCallback(){
      _self.spinnerShown = false;
      _self.responseRecieved = true;
      _self.emailSent = false;
    });
  }
}
</script>

<template>
  <div class="ssoformwrapper">
    <div class="text-center">
      <a href="https://www.opendo.dk/" target="_blank">
        <img :src="require('@/assets/logo.svg')" class="logo" alt="" />
      </a>
    </div>

    <p class="header">{{title}}</p>

    <div v-if="responseRecieved" class="text-center responseRecieved">
      <p v-if="emailSent">Vi har modtaget din forespørgsel og vender tilbage hurtigst muligt.</p>
      <p v-else>Der skete desværre en fejl.<br>Prøv venligst igen eller kontakt os.</p>
    </div>

    <div v-if="spinnerShown">
      <p class="spinnerdesc text-center">Sender formular data</p>
      <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
      </div>
    </div>

    <input type="hidden" name="token" id="token">

    <div v-if="!requested && this.$props.requestId == this.requestIds[0]">
      <div class="row">
        <div class="col fields">
          <p>Leasingaftale nummer</p>
          <input id="BestillingsarkDebitorskifteLeasingNumber" v-model="BestillingsarkDebitorskifte.LeasingNumber">

          <p>Dato for debitorskifte</p>
          <p class="fielddesc fielddeschighlighted">(standard vil være løbende måned + 1 måned)</p>

          <date-picker
            prefix-class="xmx"
            :placeholder="datePlaceholder"
            v-model="BestillingsarkDebitorskifte.Date"
            valueType="format"
            :format="dateFormat"
            :editable="false"
            :clearable="false"
            :disabled-date="disableDateNotFirst"
          ></date-picker>

          <p>CVR.nr. på udtrædende debitor</p>
          <input v-model="BestillingsarkDebitorskifte.ExitingDebitor" id="BestillingsarkDebitorskifteExitingDebitor">

          <p class="fieldsubheader">Indtrædende debitor</p>
          <p>Navn</p>
          <input v-model="BestillingsarkDebitorskifte.DebitorName">
          <p>CVR</p>
          <input v-model="BestillingsarkDebitorskifte.DebitorCVR">
          <p>Mail</p>
          <input v-model="BestillingsarkDebitorskifte.DebitorMail">
          <p>Reg. nr. og konto nr. til BS</p>
          <input v-model="BestillingsarkDebitorskifte.DebitorAccount">
          <p>Tlf. nr.</p>
          <input v-model="BestillingsarkDebitorskifte.DebitorPhone">
          <p>Hvem er underskriver (jf. tegningsregler)</p>
          <input v-model="BestillingsarkDebitorskifte.DebitorSigner">
          <p>Mail på underskriver(e)</p>
          <input v-model="BestillingsarkDebitorskifte.DebitorMails">
          <p>Forsikringsselskab</p>
          <input v-model="BestillingsarkDebitorskifte.DebitorInsurance">
          <p>Kautionist</p>
          <input v-model="BestillingsarkDebitorskifte.DebitorSurety">
          <p>Navn</p>
          <input v-model="BestillingsarkDebitorskifte.DebitorSuretyName">
          <p>CVR</p>
          <input v-model="BestillingsarkDebitorskifte.DebitorSuretyCvr">
          <p>E-mail</p>
          <input v-model="BestillingsarkDebitorskifte.DebitorSuretyEmail">
          <p>Tlf. nr.</p>
          <input v-model="BestillingsarkDebitorskifte.DebitorSuretyPhone">
          <p>Adresse</p>
          <input v-model="BestillingsarkDebitorskifte.DebitorSuretyAddress">
          <p>Postnummer</p>
          <input v-model="BestillingsarkDebitorskifte.DebitorSuretyZip">

          <p>Gebyr (gebyret tilfalder banken og kan ændres)</p>
          <input v-model="BestillingsarkDebitorskifte.DebitorFee">
          <p>Gebyr for omregistrering i DMR (gælder kun ved indregistreret køretøjer)</p>
          <input v-model="BestillingsarkDebitorskifte.ReRegistrationFee">

          <p>Allonge sendes til</p>
          <select v-model="BestillingsarkDebitorskifte.Allonge">
            <option value="Kunde" selected="selected">Kunde</option>
            <option value="Rådgiver">Rådgiver</option>
          </select>

          <p>Særlige bemærkninger</p>
          <textarea v-model="BestillingsarkDebitorskifte.Remarks"></textarea>
        </div>
      </div>
    </div>

    <div v-if="!requested && this.$props.requestId == this.requestIds[1]">
      <div class="row">
        <div class="col fields">
          <p>Leasingaftale nummer</p>
          <input id="BestillingsarkIndfrielserLeasingNumber" v-model="BestillingsarkIndfrielser.LeasingNumber">
          <p>Indfrielsesdato</p>
          <p class="fielddesc">(altid ultimo måned og aldrig længere frem end indeværende måned)</p>

          <date-picker
            prefix-class="xmx"
            :placeholder="datePlaceholder"
            v-model="BestillingsarkIndfrielser.RedemptionDate"
            valueType="format"
            :format="dateFormat"
            :editable="false"
            :clearable="false"
            :disabled-date="disableDateNotLast"
          ></date-picker>

          <p>Indfri med alle fremtidige ydelser</p>
          <p class="fielddesc" style="margin-bottom: 1px">(indfri med al fremtidig renteindtægt)</p>
          <p>eller indfri til nedskrevet balance</p>
          <p class="fielddesc">(indfri uden fremtidig renteindtægt)</p>
          <select v-model="BestillingsarkIndfrielser.BackDiscounting">
            <option value="Alle fremtidige ydelser" selected="selected">Alle fremtidige ydelser</option>
            <option value="Nedskrevet balance">Nedskrevet balance</option>
          </select>
          <p>Indfrielsesgebyr</p>
          <p class="fielddesc">(kan kun opkræves, hvis der vælges indfri til nedskrevet balance)</p>
          <input v-model="BestillingsarkIndfrielser.RedemptionFee">

          <p class="fieldsubheader">Betalingsadvis udstedes til</p>
          <p>CVR</p>
          <input id="BestillingsarkIndfrielserPaymentAdviceCVR" v-model="BestillingsarkIndfrielser.PaymentAdviceCVR">
          <p>Firmanavn</p>
          <input v-model="BestillingsarkIndfrielser.PaymentAdviceCompanyName">
          <p>Mail</p>
          <input v-model="BestillingsarkIndfrielser.PaymentAdviceMail">

          <p>Særlige bemærkninger</p>
          <textarea v-model="BestillingsarkIndfrielser.Remarks"></textarea>
        </div>
      </div>
    </div>

    <div v-else-if="!requested && this.$props.requestId == this.requestIds[2]">
      <div class="row">
        <div class="col fields">
          <p>Leasingaftale nummer</p>
          <input id="BestillingsarkForlaengelserLeasingNumber" v-model="BestillingsarkForlaengelser.LeasingNumber">
          <p>CVR.nr. på debitor</p>
          <input v-model="BestillingsarkForlaengelser.DebitorCVR" id="BestillingsarkForlaengelserDebitorCVR">

          <p style="font-weight: bold">Marginalen fra tidligere aftale bibeholdes.</p>
          <p><br></p>
          <p>Aftalen forlænges med x antal måneder</p>
          <input v-model="BestillingsarkForlaengelser.ProlongMonths">
          <p>Indtast enten ny ydelse eller ny restværdi (Vi beregner enten ny restværdi eller ny ydelse)</p>
          <select v-model="BestillingsarkForlaengelser.ResidualType">
            <option value="Restværdi" selected="selected">Restværdi</option>
            <option value="Ydelse">Ydelse</option>
          </select>
          <p>Kr.</p>
          <input v-model="BestillingsarkForlaengelser.ResidualValue">

          <p>Ekspeditionsgebyr</p>
          <p class="fielddesc">(Gebyret tilfalder banken og kan ændres)</p>
          <input v-model="BestillingsarkForlaengelser.HandlingFee">
          <p>Skal omkostninger medfinansieres</p>
          <select v-model="BestillingsarkForlaengelser.CostsCofinanced">
            <option value="Ja" selected="selected">Ja</option>
            <option value="Nej">Nej</option>
          </select>
          <p>Hvem er underskriver (jf. tegningsregler)</p>
          <input v-model="BestillingsarkForlaengelser.DebitorSigner">
          <p>Mail på underskriver(e)</p>
          <input v-model="BestillingsarkForlaengelser.DebitorMails">
          <p>Allonge sendes til</p>
          <select v-model="BestillingsarkForlaengelser.Allonge">
            <option value="Kunde" selected="selected">Kunde</option>
            <option value="Rådgiver">Rådgiver</option>
          </select>

          <p>Særlige bemærkninger</p>
          <textarea v-model="BestillingsarkForlaengelser.Remarks"></textarea>
        </div>
      </div>
    </div>

    <div class="text-center">
      <button v-if="!requested" type="button" class="btn-solid-secondary" v-on:click="submit()">Send</button>
    </div>
  </div>
</template>

<style lang="scss">
@import "../../views/banklogin/calendar";
@import "../../views/banklogin/datepicker";

.ssoformwrapper {
  margin-bottom: 40px;
}

.logo {
  width: 200px;
  margin-top: -60px;
}

.btn-outline-secondary,
.btn-link-secondary,
.btn-solid-secondary {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #FFFFFF;
  border: none;
  box-sizing: border-box;
  padding: 32px;
  padding-top: 11px;
  padding-bottom: 14px;
  background: #69BDB6;
  border-radius: 100px;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 20px;

  &:disabled {
    background: #C7E5E4;
  }
}

.header {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 40px;
}

.fields {
  max-width: 500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  padding: 0;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    color: #1F4743;
    text-align: left;
    margin-bottom: 8px;
  }

  p.fieldsubheader {
    font-size: 18px;
    font-weight: bold;
  }
}

textarea,
select,
input {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #C7E5E4;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #1F4743;
  padding: 20px;
  padding-top: 16px;
  margin-bottom: 30px;
}

textarea {
  min-height: 130px;
}

.fielddesc {
  font-size: 13px !important;
  margin-top: -10px;
}

.fielddeschighlighted {
  font-size: 15px !important;
  font-weight: bold !important;
  color: red !important;
}

.spinnerdesc {
  margin-bottom: 20px;
  font-size: 1.6rem;
}

.responseRecieved {
  font-size: 1.6rem;
}
</style>
