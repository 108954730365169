<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class TemporaryHomepage extends Vue {

}
</script>

<template>
  <div class="hero-section">
    <div class="wrap">
      <div class="content">
        <p class="header">Opendos hjemmeside er under ombygning</p>

        <p>Vi arbejder på højtryk for at opdatere Opendos hjemmeside.</p>
        <p>Opdateringen sker i forbindelse med at Volkswagen Semler Leasing Danmark A/S har købt rettighederne til Opendo domænet, underliggende systemer og udvalgte medarbejdere.</p>
        <p>Mens vi lægger sidste hånd på de nye opdateringer, vil der være begrænset adgang til indhold på siden.</p>
        <p>Vi glæder os til at byde jer velkommen i det nye Opendo.</p>
        <p>Tak for jeres tålmodighed!</p>

        <p><br><br><b>Opendo Partner Service</b></p>
        <p>Hvis du skal I kontakt med Opendo Partner Service, skal du henvende dig på tlf: 7071 6312 eller på mail: <a href="mailto:leasing@opendo.dk">leasing@opendo.dk</a>.</p>

        <p>Partner Service har åbent mandag til fredag fra kl. 09-16.</p>

        <p>Hvis du skal tilgå Banklogin, skal du trykke på "Log på" oppe i højre hjørne.</p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.header {
  font-size: 24px;
  font-weight: bold;
  line-height: 146px;
}
.hero-section {
  background: #e8f5f4;
  border-radius: 40px;
  padding-bottom: 170px;
  text-align: center;
  padding-top: 80px;
}
@media only screen and (min-width : 1600px) {
  .hero-section {
    height: calc(100vh - 100px);
  }
}
.content {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #4c6c69;
  margin-top: 40px;
  padding-bottom: 40px;
}
.content > p {
  margin-bottom: 15px;
  line-height: 24px;
}
.content a {
  color: #4c6c69;
}
.wrap {
  margin-left: auto;
  margin-right: auto;
}
</style>